
@color-fff: #fff;
@color-f3: #f7f7f7;
@color-f2: #f2473a;
@color-ce: #CECECE;
@color-d9: #d9d9d9;
@color-999: #999999;
@color-eee: #eeeeee;
@color-be: #bebebe;
@color-333: #333333;
@color-ffce31: #ffce31;
@color-79e4cd: #79e4cd;

// font size type
.fontSize(@size) {
    font-size: @size;
}
// color
.color(@color) {
    color: @color;
}
// 布局
.align-display(@align, @display) {
    text-align: @align;
    display: @display;
}
.margin(@size: 10px){
    margin: @size;
}
