.loading{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    background: #fff;
    align-items: center;
}
@primary-color: #f2473a;