@charset 'utf-8';
@import 'normalize.css';
@import '~antd/dist/antd.less';
@import './common.less';

* {
    margin: 0;
    padding: 0;
}

html,
body,
#root {
    height: 100%
}

li {
    list-style: none;
}

// font size type
.fontSize12 {
    .fontSize(12px);
}

.fontSize14 {
    .fontSize(14px);
}

.fontSize20 {
    .fontSize(20px);
}

.fontWeight {
    font-weight: bold;
}

// color
.color333 {
    .color(@color-333)
}

.color999 {
    .color(@color-999);
}

// 布局
.alignCenter {
    .align-display(center, block);
}

.alignRight {
    .align-display(right, block);
}

::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
}

::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(192, 14, 14, 0.8);
    background: #f2473a;
}

::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(192, 14, 14, 0.8);
    border-radius: 10px;
    background: #ffb5b0;
}
@primary-color: #f2473a;